import React from 'react';
import HeaderNav from '../../nav/HeaderNav';
import { products, services } from '../../../models';
import { Row, Col } from 'react-bootstrap';
import Footer from '../../footer/Footer';
import CardProduct from '../../cardProduct/CardProduct';
import CardService from '../../cardService/CardService';
import './ServicePage.scss';

export function Service() {
	return (
		<div className="">
			<h1 className="d-none">Services</h1>
			<HeaderNav currentPageName="Services" />
			<div className="m-auto" style={{ maxWidth: '85rem' }}>
				<h2 className="hard-font-style mx-3">Products</h2>
				<Row className="p-0 m-0">
					{products.map((product) => (
						<Col sm={12} md={6} xl={4} className="p-0 m-0">
							<CardProduct
								product={product}
								index={product.title}
							></CardProduct>
						</Col>
					))}
				</Row>
				<h2 className="hard-font-style  mx-3">Services</h2>
				<Row className="p-0 m-0">
					{services.map((service) => (
						<Col sm={12} md={6} xl={4} className="p-0 m-0">
							<CardService
								service={service}
								index={service.title}
							></CardService>
						</Col>
					))}
				</Row>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default Service;
