import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IService } from '../../models/interfaces';
import './CardService.scss';

interface CardServiceInterfaceProps {
	service: IService;
	index: string;
}

export const CardService: React.FC<CardServiceInterfaceProps> = ({
	service,
	index,
}) => {
	const navigate = useNavigate();

	return (
		<div key={index} className="cardService mx-auto">
			<h3 className="labelService">{service.title}</h3>
			<p className={`description font-weight-bold expanded`}>
				{service.mainDescription}
			</p>
			<div className="d-flex justify-content-center">
				<button
					onClick={() => navigate('/contact/' + service.title)}
					className="rounded-pill contactBtn p-2 px-3"
				>
					Get in touch
				</button>
			</div>
		</div>
	);
};

export default CardService;
