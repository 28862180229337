import React, { useState } from 'react'; // Import the React module
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HiMenu } from 'react-icons/hi';
import { RxCross1 } from 'react-icons/rx';
import logo from '../../imgs/logo.png';
import { Row, Col } from 'react-bootstrap';

import './HeaderNav.scss';

interface HeaderNavProps {
	currentPageName: string;
}

const HeaderNav: React.FC<HeaderNavProps> = ({ currentPageName }) => {
	const [isToggled, setIsToggled] = useState(false);

	const handleToggleClick = () => {
		setIsToggled(!isToggled);
	};
	return (
		<Navbar expand="lg" className="bg_white nav_bar_whole" sticky="top">
			<h2 className="d-none">Navigation</h2>
			<Container className="w-100">
				<Row className="w-100 mx-auto">
					<Col md={12} lg={0}>
						<div className="d-md-flex d-lg-none justify-content-center my-2">
							<img
								className="nav-logo mx-auto"
								src={logo}
								width={'50px'}
								height={'50px'}
								alt=""
							/>
						</div>
					</Col>
					<Col md={12} lg={12}>
						<Navbar.Toggle
							className={` toggler mx-auto px-auto p-0 ${
								isToggled ? 'active' : ''
							}`}
							aria-controls="basic-navbar-nav "
							onClick={handleToggleClick}
						>
							<HiMenu
								className={`mx-auto menu-icon ${isToggled ? 'hidden' : ''}`}
							/>
							<RxCross1 className={`close-icon ${isToggled ? '' : 'hidden'}`} />
						</Navbar.Toggle>

						<Navbar.Collapse id="basic-navbar-nav ">
							<Nav className="text-center mx-auto ">
								<Nav.Link
									className={`nav-links ${
										currentPageName === 'Home' ? 'current' : ''
									}`}
									href="/"
								>
									Home
								</Nav.Link>
								<Nav.Link
									className={`nav-links ${
										currentPageName === 'Services' ? 'current' : ''
									}`}
									href="/services"
								>
									Services
								</Nav.Link>
								<div className="d-none d-lg-flex justify-content-center">
									<a href="/">
										<img
											className="nav-logo"
											src={logo}
											width={'50px'}
											height={'50px'}
											alt=""
										/>
									</a>
								</div>
								<Nav.Link
									className={`nav-links ${
										currentPageName === 'About' ? 'current' : ''
									}`}
									href="/about"
								>
									About
								</Nav.Link>
								<Nav.Link
									className={`nav-links ${
										currentPageName === 'Contact' ? 'current' : ''
									}`}
									href="/contact"
								>
									Contact
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Col>
				</Row>
			</Container>
		</Navbar>
	);
};

export default HeaderNav;
