import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { IProject } from "../../models/interfaces"
import './CardProject.scss';



// background: linear-gradient(218deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
interface CardProjectInterfaceProps {
  project: IProject;
}

export const CardProject: React.FC<CardProjectInterfaceProps> = ({
  project
}) => {
  const [gradientDegree] = useState<number>(Math.floor(Math.random() * 360));


  const gradientStyle = {
    //     background: linear-gradient(145deg, #eeaeca, #94bbe9);
    background: `linear-gradient(${gradientDegree}deg,#eeaeca, #94bbe9)`,
    backgroundSize: `400% 400%`,
    webkitAnimation: `CardAnimation 10s ease infinite`,
    mozAnimation: `CardAnimation 10s ease infinite`,
    oAnimation: `CardAnimation 10s ease infinite`,
    animation: `CardAnimation 10s ease infinite`,
  }

  const calculateMonthsPassed = () => {
    // Set the target date (October 25, 2023)
    const targetDate = new Date('2023-10-25');

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in months
    return ((currentDate.getFullYear() - targetDate.getFullYear()) * 12 + currentDate.getMonth() - targetDate.getMonth() + 1);
  };




  return (
    <Col className='p-0 m-0 d-flex justify-content-center' sm={12} md={6} lg={6} xl={4}>
      <div className='p-3 m-2 cardProject d-flex justify-content-center'>
        <div className=''>
          <div className="d-flex justify-content-center">
            <p className={`text-center labelProject ${project.ProjectLabel} hard-font-style`}>{project.ProjectLabel}</p>
          </div>
          <a href={project.ProjectWebsite}>
            <Row>
              <Col sm={4} className='d-flex justify-content-center'>
                <img src={project.CompanyLogo} className='imgProject' alt={project.CompanyName + " Logo"} />
              </Col>
              <Col className='d-flex justify-content-start align-items-center projectTitle'>
                <h3>{project.CompanyName}</h3>
              </Col>
            </Row>
          </a>
          <div className='mainTextProject mx-1 px-4' style={gradientStyle}>
            <div>
              <h4>About {project.CompanyNameShort}</h4>
              <p className='paragraphProject'>
                {project.CompanyDescription}
              </p>
              <h4>Contribution</h4>
              <ul className='paragraphProject ulProject'>
                {project.ProjectContribution.map(contribution =>
                  <li>{contribution}</li>
                )}
              </ul>
              <h4>Duration</h4>

              <p className='paragraphProject'>
                {project.ProjectPeriod} <br /> {project.ProjectDuration == "" ? calculateMonthsPassed() + " Months" : project.ProjectDuration}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default CardProject;
