import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query'
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import HomePage from './components/pages/home/HomePage';
import AboutPage from './components/pages/about/AboutPage';
import ContactPage from './components/pages/contact/ContactPage';
import ServicePage from './components/pages/services/ServicePage';
import './index.scss';



//import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient()


const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact/:redirectReason" element={<ContactPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/services" element={<ServicePage />} />
            </Routes>
          </BrowserRouter>
        </ChakraProvider>
      </QueryClientProvider >
    </React.StrictMode >
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
