import React from 'react';
import { Canvas } from 'react-three-fiber';
import HeaderNav from '../../nav/HeaderNav';
import CardProject from '../../cardProject/CardProject';
import { projects } from '../../../models/db'
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FiArrowRight, FiLayers } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import imgProfile from '../../../imgs/profiel-foto.jpg'
import Footer from '../../footer/Footer';
import './AboutPage.scss';

export function About() {


  return (
    <div className=''>
      <h1 className="d-none">About</h1>
      <HeaderNav currentPageName="About" />
      <div className="m-auto" style={{ maxWidth: '85rem' }}>
        <div className="about_me_short">
          <Row >
            <Col md={6} >
              <h2 className='hard-font-style'>About us</h2>
              <div className="d-flex justify-content-center" >
                <img className="rounded-circle" width={"40%"} src={imgProfile} alt="" />
              </div>
            </Col>
            <Col md={6} className="roboto-font-style h3 font-weight-normal about_me_text">
              <p>Hi, I'm Daniel, founder of DS. I specialize in full-stack development, crafting tailored websites.</p>

              <p>At DS, we excel in efficient solutions through collaboration—shaping interfaces and architecting robust back-end systems with a commitment to excellence.</p>

              <p><b>Curious about our help?</b> DS collaborates with you to create custom websites tailored to your needs. We're not just here to provide solutions; we're dedicated to understanding and meeting your unique requirements.</p>
            </Col>
          </Row>
          <br />
          <br />
          <h2 className='hard-font-style'>What projects have we worked on?</h2>
          <Row className='p-0 m-0'>
            {
              projects.map(project => (
                <CardProject
                  project={project}
                />
              ))
            }

          </Row>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default About;
