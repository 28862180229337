import React, { useState } from 'react';
import { Row, Col, Button as BootstrapButton } from 'react-bootstrap';
import { FiArrowRight } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineLinkedin, AiOutlineSend } from 'react-icons/ai';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { Input, FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import HeaderNav from '../../nav/HeaderNav';
import Footer from '../../footer/Footer';
import Loader from '../../loader/Loader';
import './ContactPage.scss';

export function Contact() {
	const [personalEmail, setPersonalEmail] = useState('');
	const [isEmailInjected, setIsEmailInjected] = useState(false);
	const { redirectReason } = useParams();

	const handleEmailClick = () => {
		if (!isEmailInjected) {
			setPersonalEmail('daniel.savin@ds-innovation.dev');
			setIsEmailInjected(true);
		}
	};
	const [personalPhoneNumber, setPersonalPhoneNumber] = useState('');
	const [isPhoneNumberInjected, setIsPhoneNumberInjected] = useState(false);

	const handlePhoneClick = () => {
		if (!isPhoneNumberInjected) {
			setPersonalPhoneNumber('+32 483 25 66 10');
			setIsPhoneNumberInjected(true);
		}
	};

	const [isSuccessful, setIsSuccessful] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [organization, setOrganization] = useState('');
	const [message, setMessage] = useState(
		redirectReason
			? 'My inquiry is related to: ' + redirectReason + '\n\n\n'
			: ''
	);

	const [isFirstNameValid, setIsFirstNameValid] = useState(true);
	const [isLastNameValid, setIsLastNameValid] = useState(true);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isMessageValid, setIsMessageValid] = useState(true);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);

		// Trigger validation before submitting the form
		const localFirstNameValidation = firstName.trim() !== '';
		const localLastNameValidation = lastName.trim() !== '';
		const localEmailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
			email.trim()
		);
		const localMessageValidation = message.trim() !== '';

		setErrorMessage('');
		setIsFirstNameValid(localFirstNameValidation);
		setIsLastNameValid(localLastNameValidation);
		setIsEmailValid(localEmailValidation);
		setIsMessageValid(localMessageValidation);

		// Check if all inputs are valid before proceeding

		if (
			localFirstNameValidation &&
			localLastNameValidation &&
			localEmailValidation &&
			localMessageValidation
		) {
			try {
				// Construct the request body
				const requestBody = {
					firstName,
					lastName,
					email,
					phone,
					organization,
					message,
				};

				// PRD  https://us-central1-ds-innovation.cloudfunctions.net/contactFormSubmission
				// DEV  http://127.0.0.1:5001/ds-innovation/us-central1/contactFormSubmission
				const response = await fetch(
					'https://us-central1-ds-innovation.cloudfunctions.net/contactFormSubmission',
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(requestBody),
					}
				);

				if (response.ok) {
					setIsSuccessful(true);
					console.log('Form submitted successfully!');
				} else {
					setErrorMessage(await response.text());
					console.log('Form submission failed. Please check your inputs.');
				}
			} catch (error) {
				setErrorMessage(`Something went wrong: ${error}`);
				console.error('Error:', error);
				console.log('Internal Server Error');
			}
		} else {
			// Initialize an array to store error messages
			const validationErrors: string[] = [];

			// Perform form validation
			if (!localFirstNameValidation) {
				validationErrors.push('Please provide your first name.');
			}

			if (!localLastNameValidation) {
				validationErrors.push('Please provide your last name.');
			}

			if (!localEmailValidation) {
				validationErrors.push('Please provide a valid email address.');
			}

			if (!localMessageValidation) {
				validationErrors.push('Please include a message.');
			}

			// Check if there are any validation errors
			if (validationErrors.length > 0) {
				// If there are errors, return a consolidated error message
				setErrorMessage(
					`Something's not quite right. Could you glance over the following for us:\n${validationErrors.join(
						'\n'
					)}`
				);
			}
			console.log(
				`Something's not quite right. Could you glance over the following for us:\n${validationErrors.join(
					'\n'
				)}`
			);
		}

		setIsLoading(false);
	};

	return (
		<div className="">
			<h1 className="d-none">Contact</h1>
			<HeaderNav currentPageName="Contact" />
			<div className="m-auto" style={{ maxWidth: '85rem' }}>
				<div className="about_me_short">
					<h2 className="hard-font-style">Reach out to us!</h2>

					<Row>
						<Col className="d-flex justify-content-center m-0 p-0 m-2">
							<div className="contactCard">
								<a
									className="contactLogoLink hard-font-style  d-flex justify-content-center align-items-center"
									href={`mailto:${personalEmail}`}
									onClick={handleEmailClick}
								>
									<HiOutlineMail /> Email
								</a>
								<br />
								<div className="d-flex justify-content-center text-center">
									<h3 className="contactTitle">
										We will try to answer your emails as fast as possible!
									</h3>
								</div>
								<br />
								<div className="d-flex justify-content-center">
									<BootstrapButton
										className="get_in_touch_btn  d-flex justify-content-center"
										href={`mailto:${personalEmail}`}
										onClick={handleEmailClick}
									>
										Email us <FiArrowRight className="get_in_touch_btn_icon" />
									</BootstrapButton>
								</div>
							</div>
						</Col>
						<Col className="d-flex justify-content-center m-0 p-0  m-2">
							<div className="contactCard">
								<a
									className="contactLogoLink hard-font-style  d-flex justify-content-center align-items-center"
									href="https://www.linkedin.com/company/ds-innovation"
									onClick={handlePhoneClick}
								>
									<AiOutlineLinkedin /> LinkedIn
								</a>
								<br />
								<div className="d-flex justify-content-center text-center">
									<h3 className="contactTitle">
										Send us a quick message on LinkedIn
									</h3>
								</div>
								<br />
								<div className="d-flex justify-content-center">
									<BootstrapButton
										className="get_in_touch_btn  d-flex justify-content-center"
										href="https://www.linkedin.com/company/ds-innovation"
										onClick={handlePhoneClick}
									>
										Message us{' '}
										<FiArrowRight className="get_in_touch_btn_icon" />
									</BootstrapButton>
								</div>
							</div>
						</Col>
						<Col className="d-flex justify-content-center m-0 p-0  m-2">
							<div className="contactCard">
								<a
									className="contactLogoLink hard-font-style  d-flex justify-content-center align-items-center"
									href={`tel:${personalPhoneNumber}`}
									onClick={handlePhoneClick}
								>
									<BsTelephone /> &nbsp; Phone
								</a>
								<br />
								<div className="d-flex justify-content-center text-center">
									<h3 className="contactTitle">
										We are available <br /> from 10AM until 10PM
									</h3>
								</div>
								<br />
								<div className="d-flex justify-content-center">
									<BootstrapButton
										className="get_in_touch_btn  d-flex justify-content-center"
										href={`tel:${personalPhoneNumber}`}
										onClick={handlePhoneClick}
									>
										Phone us <FiArrowRight className="get_in_touch_btn_icon" />
									</BootstrapButton>
								</div>
							</div>
						</Col>
					</Row>

					{isSuccessful ? (
						''
					) : (
						<div className="d-flex justify-content-center mt-5">
							<hr className="orHr" />
							<p className="hard-font-style h2">OR</p>
							<hr className="orHr" />
						</div>
					)}

					<div className="contactFormWrapper mt-3 mx-auto">
						<h2 className="text-center hard-font-style pt-3">
							{isSuccessful
								? 'Thank you for contacting us!'
								: isLoading
								? 'In progress... almost there!'
								: 'Contact us directly'}
						</h2>
						<br />
						{isSuccessful ? (
							<div className="text-center font-weight-bold">
								<h2>We've successfully received your message!</h2>
								<br />
								<a href="/">
									<FaRegCircleCheck
										className="mx-auto text-success"
										size={60}
									></FaRegCircleCheck>
								</a>
								<br />
							</div>
						) : isLoading ? (
							<div className="d-flex justify-content-center">
								<Loader />
							</div>
						) : (
							<form onSubmit={handleSubmit}>
								<Row>
									<Col sm={6}>
										<FormControl isRequired>
											<FormLabel>First name</FormLabel>
											<Input
												placeholder="Elon"
												id="firstName"
												name="firstName"
												autoComplete="given-name"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
												isInvalid={!isFirstNameValid}
											/>
										</FormControl>
									</Col>
									<Col sm={6}>
										<FormControl isRequired>
											<FormLabel>Last name</FormLabel>
											<Input
												placeholder="Muskington"
												id="lastName"
												name="lastName"
												autoComplete="family-name"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
												isInvalid={!isLastNameValid}
											/>
										</FormControl>
									</Col>
								</Row>

								<Row>
									<Col sm={6}>
										<FormControl isRequired>
											<FormLabel>Email</FormLabel>
											<Input
												type="email"
												placeholder="elon.muskington@spacex.com"
												id="email"
												name="email"
												autoComplete="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												isInvalid={!isEmailValid}
											/>
										</FormControl>
									</Col>
									<Col sm={6}>
										<FormControl>
											<FormLabel>Phone</FormLabel>
											<Input
												type="tel"
												placeholder=""
												id="phone"
												name="phone"
												autoComplete="tel"
												value={phone}
												onChange={(e) => setPhone(e.target.value)}
											/>
										</FormControl>
									</Col>
								</Row>

								<FormControl>
									<FormLabel>Company</FormLabel>
									<Input
										type="text"
										placeholder="Space X"
										id="organization"
										name="organization"
										autoComplete="organization"
										value={organization}
										onChange={(e) => setOrganization(e.target.value)}
									/>
								</FormControl>
								<br />
								<FormControl isRequired>
									<FormLabel>Message</FormLabel>
									<Textarea
										placeholder="Hi there! Let's explore the tech frontier together."
										size="md"
										rows={7}
										value={message}
										onChange={(e) => setMessage(e.target.value)}
										isInvalid={!isMessageValid}
									/>
								</FormControl>
								<br />

								{!errorMessage ? (
									''
								) : (
									<div
										className="d-flex justify-content-center alert alert-danger"
										role="alert"
									>
										<span
											className="font-weight-bold"
											dangerouslySetInnerHTML={{
												__html: errorMessage.replace(/\n/g, '<br/>- '),
											}}
										/>
									</div>
								)}
								<div className="d-flex justify-content-center">
									<BootstrapButton
										type="submit"
										className="get_in_touch_btn d-flex justify-content-center align-items-center"
									>
										Send &nbsp;&nbsp;&nbsp;
										<AiOutlineSend className="get_in_touch_btn_icon" />
									</BootstrapButton>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default Contact;
