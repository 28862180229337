import React, { useState } from 'react';
import { IProduct } from '../../models/interfaces';
import { MdOutlineExpandMore } from 'react-icons/md';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import './CardProduct.scss';

interface CardProductInterfaceProps {
	product: IProduct;
	index: string;
}

export const CardProduct: React.FC<CardProductInterfaceProps> = ({
	product,
	index,
}) => {
	const [toggle, setToggle] = useState(false);
	const navigate = useNavigate();

	return (
		<div key={index} className="cardProduct mx-auto">
			<button
				className="expandButton d-flex justify-content-center mb-3"
				onClick={() => setToggle(!toggle)}
			>
				<MdOutlineExpandMore
					size={36}
					className={`expandIcon ${toggle ? 'expanded' : ''}`}
				/>
			</button>
			<h3 className="labelProduct">{product.title}</h3>
			<p className={`description font-weight-bold ${toggle ? 'expanded' : ''}`}>
				{product.mainDescription}
			</p>
			<div className="advantagesProduct pt-2">
				{product.advantages.map((advantage, internalIndex) => (
					<p className="d-flex font-weight-bold" key={internalIndex}>
						<IoIosCheckmarkCircle
							size={30}
							className="advantageCircle"
						></IoIosCheckmarkCircle>
						{advantage}
					</p>
				))}
			</div>

			<p className={`description ${toggle ? 'expanded' : ''}`}>
				{product.subDescription}
			</p>
			<div className="d-flex justify-content-center">
				<button
					onClick={() => navigate('/contact/' + product.title)}
					className="rounded-pill contactBtn p-2 px-3"
				>
					Get in touch
				</button>
			</div>
		</div>
	);
};

export default CardProduct;
