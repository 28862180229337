import React from 'react'; // Import the React module
import HashLoader from "react-spinners/HashLoader";

import './loader.scss';

function Loader() {
    return (
        <HashLoader
            color="#FFA726"
            size={60}
            speedMultiplier={1.25}
        />
    );
}

export default Loader;


