import React from 'react'; // Import the React module
import './Footer.scss';

function Footer() {
    return (
        <div className='text-secondary small font-weight-bold'>
            <hr />
            <p className='d-flex justify-content-center'>{new Date().getFullYear()} DS-innovation BV</p>
            <p className='d-flex justify-content-center'>BE 1001.488.871</p>
        </div>
    );
}

export default Footer;


