import React, { useRef } from 'react';
import { useLoader, useFrame } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Group, Box3, Vector3, Euler } from 'three';

interface ModelProps {
  position?: [number, number, number];
  rotation?: [number, number, number];
  scale?: [number, number, number];
}

const Model: React.FC<ModelProps> = ({ position = [0, 0, 0], rotation = [0, 0, 0], scale = [1, 1, 1] }) => {
  const gltf = useLoader(GLTFLoader, '/models/logo-3d.gltf');
  const groupRef = useRef<Group>(null);
  const isDragging = useRef(false);
  const initialRotation = useRef<Euler>(new Euler());
  const rotationSpeed = 0.002; // Adjust the rotation speed as needed

  React.useEffect(() => {
    if (groupRef.current && gltf.scene) {
      const wrapperGroup = new Group(); // Create a new group to act as a wrapper
      wrapperGroup.add(gltf.scene); // Add the gltf scene to the wrapper group
      groupRef.current.add(wrapperGroup); // Add the wrapper group to the main group

      const boundingBox = new Box3().setFromObject(gltf.scene);
      const center = boundingBox.getCenter(new Vector3());

      wrapperGroup.position.set(-center.x, -center.y, -center.z); // Set position for the wrapper group
    }
  }, [gltf.scene]);

  useFrame(() => {
    if (groupRef.current) {
      // Rotate the object horizontally while dragging
      if (isDragging.current) {
        const movementX = mouse.current.clientX - previousPointer.current.x;
        const movementY = mouse.current.clientY - previousPointer.current.y;
        groupRef.current.rotation.z -= movementX * rotationSpeed;
        groupRef.current.rotation.x -= movementY * rotationSpeed;
        previousPointer.current.x = mouse.current.clientX;
        previousPointer.current.y = mouse.current.clientY;
      } else {
        // Rotate the object horizontally automatically
        groupRef.current.rotation.z += rotationSpeed;
      }
    }
  });

  const mouse = useRef({ clientX: 0, clientY: 0 });
  const previousPointer = useRef({ x: 0, y: 0 });

  const onPointerDown = (e: any) => {
    e.stopPropagation();
    isDragging.current = true;
    mouse.current.clientX = e.clientX;
    mouse.current.clientY = e.clientY;
    previousPointer.current.x = e.clientX;
    previousPointer.current.y = e.clientY;
    initialRotation.current.copy(groupRef.current!.rotation);

    // Attach event listeners to the canvas when dragging starts
    window.addEventListener('pointerup', onPointerUp);
    window.addEventListener('pointerleave', onPointerUp);
    window.addEventListener('pointermove', onPointerMove);
  };

  const onPointerUp = () => {
    isDragging.current = false;

    // Remove event listeners when dragging ends
    window.removeEventListener('pointerup', onPointerUp);
    window.removeEventListener('pointerleave', onPointerUp);
    window.removeEventListener('pointermove', onPointerMove);
  };

  const onPointerMove = (e: any) => {
    if (!isDragging.current) return;
    mouse.current.clientX = e.clientX;
    mouse.current.clientY = e.clientY;
  };

  return (
    <group
      ref={groupRef}
      position={position}
      rotation={rotation}
      scale={scale}
      onPointerDown={onPointerDown}
    />
  );
};

export default Model;
